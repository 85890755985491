import React, { useState, Children, useRef } from "react"
import { css, jsx } from 'emotion'
import { SansDarr } from "~components/global/svg"

export const ProfileGridRow = ({ type, title, rating}) => {
  if(type === 'header'){
    return(
      <div className="profile-grid__row">
        <h4 className="s-caps">{title}</h4>
        <div>
          <span>1</span>
          <span>2</span>
          <span>3</span>
          <span>4</span>
          <span>5</span>
        </div>
      </div>
    )
  }
  else{
    return(
      <div className="profile-grid__row profile-grid__rating">
        <h5 className="s-serif">{title}</h5>
        <div>
          {[...Array(5)].map((r,i) =>
            <svg viewBox="0 0 10 10">
              <circle cx="5" cy="5" r="4.5" fill={i < rating ? 'currentColor' : 'none'} stroke="currentColor" strokeWidth="1"/>
            </svg>
          )}
        </div>
      </div>
    )
  }
}

export const AccordionSection = ({ title, children, onClick, open, highlight}) => (
  <div className={`accordion-section ${open ? 'accordion-section--open' : ''}`}>
  <button onClick={() => onClick()}
  className={open ? css`
    color: ${highlight} !important;
    &:focus{
      color: ${highlight};
    }
    ` : ''}

  >{title} <SansDarr /></button>
  {open && <div>{children}</div>}
  </div>
)

const Accordion = ({ children, highlight }) => {
  const [open, setOpen] = useState(0)
  const sections = Children.toArray(children)
  return(
    <div className="accordion">
      {sections.map((section, i) =>
        <AccordionSection {...section.props}
        onClick={() => i === open ? setOpen(null) : setOpen(i)}
        open={i === open}
        highlight={highlight}
        />
      )}
    </div>
  )
}

export default Accordion
